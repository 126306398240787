// @ts-nocheck
import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Divider } from '@mui/material';
import { TagsInput } from '../../components/TagsInput/TagsInput';
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
import TransformationsAccordion from '../TransformationsAccordion/TransformationsAccordion';
import ValidationsAccordion from '../ValidationsAccordion/ValidationsAccordion';
import { useSelector, useDispatch } from 'react-redux';
import { addNewDataType } from '../../slices/otherDataTypesSlice';
import { addNewLookUpTable } from '../../slices/lookupTablesSlice';
import { DataType } from '../../types/dataTypes';
import { addDataType, DATA_TYPE } from '../../constants/file';
import { updateFormField } from '../../slices/formFieldSlice';
import { preventEnterSubmit } from '../../utils/inputs';
import './AddFile.css';

const AddFile = ({
  fileIndex,
  fileItem,
  dataSourceIndex,
  dataTypeIndex,
  dataType,
  pathToSourceType,
}: any) => {
  const dispatch = useDispatch();
  const sourceType = useSelector(
    (state) =>
      ['formField', ...pathToSourceType].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );
  const [otherData, setOtherData] = useState(false);
  const [createDataType, setCreateDataType] = useState(false);
  const [newDataType, setNewDataType] = useState({});
  const [tempDataType, setTempDataType] = useState({});
  const [dataError, setDataError] = useState('');
  const [lookUp, setLookUp] = useState(false);
  const [createLookUpTable, setCreateLookUpTable] = useState(false);
  const [newLookUpTable, setNewLookUpTable] = useState({});
  const [tempLookUpTable, setTempLookUpTable] = useState({});
  const [lookUpError, setLookUpError] = useState('');
  const dataTypesSelector = useSelector(
    (state: RootState) => state.dataTypes.data_types,
  );

  const otherDataTypesSelector = useSelector(
    (state: RootState) => state.otherDataTypes.data_types,
  );

  const lookupTablesSelector = useSelector(
    (state: RootState) => state.lookupTables.lookup_tables,
  );

  const merchantName = useSelector(
    (state) =>
      state.formField.merchant_data_sources?.[dataSourceIndex]?.merchant_name,
  );

  const acquirerName = useSelector(
    (state) =>
      state.formField?.acquirer_data_sources?.[dataSourceIndex]?.acquirer_name,
  );

  const disableEditMerchantName =
    dataType === 'merchant_data_sources' &&
    fileItem.data_type === 'transaction_data';
  const disableEditAcquirerName =
    dataType === 'acquirer_data_sources' &&
    fileItem.data_type === 'transaction_data';

  useEffect(() => {
    if (fileItem.data_type === 'transaction_data') {
      if (dataType === 'merchant_data_sources') {
        const merchantNames = sourceType.file[fileIndex].merchant_name;

        if (
          !Array.isArray(merchantNames) ||
          merchantNames.length === 0 ||
          merchantNames.length > 1 ||
          (merchantNames.length === 1 && merchantNames[0] !== merchantName)
        ) {
          dispatch(
            updateFormField({
              path: [...pathToSourceType, 'file', fileIndex, 'merchant_name'],
              value: [merchantName],
            }),
          );
        }
      } else if (dataType === 'acquirer_data_sources') {
        const acquirerNames = sourceType.file[fileIndex].acquirer_name;

        if (
          !Array.isArray(acquirerNames) ||
          acquirerNames.length === 0 ||
          acquirerNames.length > 1 ||
          (acquirerNames.length === 1 && acquirerNames[0] !== acquirerName)
        ) {
          dispatch(
            updateFormField({
              path: [...pathToSourceType, 'file', fileIndex, 'acquirer_name'],
              value: [acquirerName],
            }),
          );
        }
      }
    }
  }, [
    dataType,
    sourceType.file[fileIndex].merchant_name,
    sourceType.file[fileIndex].acquirer_name,
    merchantName,
    fileItem.data_type,
  ]);

  useEffect(() => {
    if (fileItem?.data_type.substring(0, 6) === 'other_') {
      setOtherData(true);

      if (
        !otherDataTypesSelector?.find(
          (dataType: DataType) => dataType.value === fileItem?.data_type,
        )
      ) {
        setNewDataType({
          value: fileItem?.data_type,
          name: fileItem?.data_type
            .substring(fileItem?.data_type.indexOf('_') + 1)
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        });

        if (!DATA_TYPE[fileItem?.data_type]) {
          addDataType(
            fileItem?.data_type,
            fileItem?.data_type
              .substring(fileItem?.data_type.indexOf('_') + 1)
              .replace(/_/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
          );
        }

        dispatch(
          addNewDataType({
            value: fileItem?.data_type,
            name: fileItem?.data_type
              .substring(fileItem?.data_type.indexOf('_') + 1)
              .replace(/_/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
          }),
        );
      }
    }

    if (fileItem?.data_type.substring(0, 7) === 'lookup_') {
      setLookUp(true);

      if (
        !lookupTablesSelector?.find(
          (lookupTable: DataType) => lookupTable.value === fileItem?.data_type,
        )
      ) {
        setNewLookUpTable({
          value: fileItem?.data_type,
          name: fileItem?.data_type
            .substring(fileItem?.data_type.indexOf('_') + 1)
            .replace(/_/g, ' ')
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        });

        if (!DATA_TYPE[fileItem?.data_type]) {
          addDataType(
            fileItem?.data_type,
            fileItem?.data_type
              .substring(fileItem?.data_type.indexOf('_') + 1)
              .replace(/_/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
          );
        }

        dispatch(
          addNewLookUpTable({
            value: fileItem?.data_type,
            name: fileItem?.data_type
              .substring(fileItem?.data_type.indexOf('_') + 1)
              .replace(/_/g, ' ')
              .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase(),
              ),
          }),
        );
      }
    }
  }, []);

  const handleFileValueChange = (
    val: string,
    fileIndex: number,
    fieldName: string,
  ) => {
    const editedSourceType = structuredClone(sourceType);
    let value = val;

    if (fieldName === 'col_name') {
      value = value
        .map((s) => s.split(','))
        .flat()
        .map((s) => s.trim());
    }

    editedSourceType.file[fileIndex][fieldName] = value;

    if (fieldName === 'data_type') {
      if (val === 'other_data') {
        setOtherData(true);
        setLookUp(false);
        setCreateLookUpTable(false);
      } else if (val === 'lookup_tables') {
        setLookUp(true);
        setOtherData(false);
        setCreateDataType(false);
      }
    } else {
      setOtherData(false);
      setCreateDataType(false);
      setLookUp(false);
      setCreateLookUpTable(false);
    }
    dispatch(
      updateFormField({ path: pathToSourceType, value: editedSourceType }),
    );
  };

  const handleOtherDataChange = (
    val: string,
    fileIndex: number,
    fieldName: string,
  ) => {
    const editedSourceType = structuredClone(sourceType);
    editedSourceType.file[fileIndex][fieldName] = val;

    if (val === 'other_create_data_type') {
      setCreateDataType(true);
    } else {
      setCreateDataType(false);
      setCreateLookUpTable(false);
    }
    dispatch(
      updateFormField({ path: pathToSourceType, value: editedSourceType }),
    );
  };

  const handleLookUpTableChange = (
    val: string,
    fileIndex: number,
    fieldName: string,
  ) => {
    const editedSourceType = structuredClone(sourceType);
    editedSourceType.file[fileIndex][fieldName] = val;

    if (val === 'lookup_create_table') {
      setCreateLookUpTable(true);
    } else {
      setCreateDataType(false);
      setCreateLookUpTable(false);
    }
    dispatch(
      updateFormField({ path: pathToSourceType, value: editedSourceType }),
    );
  };

  const handleRemoveClick = (e: any, index: number) => {
    e.preventDefault();
    const editedSourceType = structuredClone(sourceType);
    editedSourceType.file.splice(index, 1);
    editedSourceType.file_count = sourceType.file_count - 1;
    dispatch(
      updateFormField({ path: pathToSourceType, value: editedSourceType }),
    );
  };

  const handleAddNewDataType = (
    e: any,
    fileIndex: number,
    fieldName: string,
  ) => {
    e.preventDefault();
    const editedSourceType = structuredClone(sourceType);

    const foundOtherDataType = otherDataTypesSelector.find(
      (dataType: DataType) =>
        dataType.name ===
          tempDataType.name
            ?.toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase(),
            ) ||
        dataType.value ===
          `other_${tempDataType.value?.toLowerCase().replace(/ /g, '_')}`,
    );

    if (!foundOtherDataType) {
      setNewDataType({
        value: `other_${tempDataType.value?.toLowerCase().replace(/ /g, '_')}`,
        name: tempDataType.name
          ?.toLowerCase()
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
      });

      dispatch(
        addNewDataType({
          value: `other_${tempDataType.value
            ?.toLowerCase()
            .replace(/ /g, '_')}`,
          name: tempDataType.name
            ?.toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        }),
      );

      if (
        !DATA_TYPE[
          `other_${tempDataType.value?.toLowerCase().replace(/ /g, '_')}`
        ]
      ) {
        addDataType(
          `other_${tempDataType.value?.toLowerCase().replace(/ /g, '_')}`,
          tempDataType.name
            ?.toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        );
      }

      editedSourceType.file[fileIndex][fieldName] = `other_${tempDataType.value
        ?.toLowerCase()
        .replace(/ /g, '_')}`;

      setCreateDataType(false);
      dispatch(
        updateFormField({ path: pathToSourceType, value: editedSourceType }),
      );
    } else {
      setDataError('Data type already exists!');
    }
  };

  const handleAddNewLookUpTable = (
    e: any,
    fileIndex: number,
    fieldName: string,
  ) => {
    e.preventDefault();
    const editedSourceType = structuredClone(sourceType);
    const foundLookUpTable = lookupTablesSelector.find(
      (lookupTable: DataType) =>
        lookupTable.name ===
          tempLookUpTable.name
            ?.toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase(),
            ) ||
        lookupTable.value ===
          `lookup_${tempLookUpTable.value?.toLowerCase().replace(/ /g, '_')}`,
    );

    if (!foundLookUpTable) {
      setNewLookUpTable({
        value: `lookup_${tempLookUpTable.value
          ?.toLowerCase()
          .replace(/ /g, '_')}`,
        name: tempLookUpTable.name
          ?.toLowerCase()
          .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
      });

      dispatch(
        addNewLookUpTable({
          value: `lookup_${tempLookUpTable.value
            ?.toLowerCase()
            .replace(/ /g, '_')}`,
          name: tempLookUpTable.name
            ?.toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        }),
      );

      if (
        !DATA_TYPE[
          `lookup_${tempLookUpTable.value?.toLowerCase().replace(/ /g, '_')}`
        ]
      ) {
        addDataType(
          `lookup_${tempLookUpTable.value?.toLowerCase().replace(/ /g, '_')}`,
          tempLookUpTable.name
            ?.toLowerCase()
            .replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase()),
        );
      }

      editedSourceType.file[fileIndex][fieldName] =
        `lookup_${tempLookUpTable.value?.toLowerCase().replace(/ /g, '_')}`;

      setCreateLookUpTable(false);
      dispatch(
        updateFormField({ path: pathToSourceType, value: editedSourceType }),
      );
    } else {
      setLookUpError('Look up table already exists!');
    }
  };

  return (
    <div className="main-file-div">
      <div className="file-div">
        <TextField
          required
          key={'file_name' + fileIndex}
          type="text"
          label="Expected File Name"
          variant="standard"
          value={fileItem.file_name}
          onChange={(e) =>
            handleFileValueChange(e.target.value, fileIndex, 'file_name')
          }
          onKeyDown={preventEnterSubmit}
        />
        <FormControl required fullWidth>
          <InputLabel id="demo-simple-select-label">File Type</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={fileItem.file_type || ''}
            label="File Type"
            variant="standard"
            onChange={(e: SelectChangeEvent) =>
              handleFileValueChange(e.target.value, fileIndex, 'file_type')
            }
          >
            <MenuItem value="csv">csv</MenuItem>
            <MenuItem value="xls">xls</MenuItem>
            <MenuItem value="xlsx">xlsx</MenuItem>
          </Select>
        </FormControl>
        <FormControl required fullWidth>
          <InputLabel id="demo-simple-select-label">Data Type</InputLabel>
          <Select
            required
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={
              otherData || fileItem?.data_type.includes('other')
                ? 'other_data'
                : lookUp || fileItem?.data_type.includes('lookup')
                  ? 'lookup_tables'
                  : fileItem?.data_type || 'csv'
            }
            label="Data Type"
            variant="standard"
            onChange={(e: SelectChangeEvent) =>
              handleFileValueChange(e.target.value, fileIndex, 'data_type')
            }
          >
            {dataTypesSelector?.map((dataType: DataType, index: number) => (
              <MenuItem key={index} value={dataType.value}>
                {dataType.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {otherData || fileItem?.data_type?.includes('other') ? (
          <FormControl required fullWidth>
            <InputLabel id="demo-simple-select-label">Other Data</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={
                createDataType || newDataType?.value === ''
                  ? 'create_data_type'
                  : createDataType && newDataType?.value !== ''
                    ? otherDataTypesSelector?.find(
                        (dataType: DataType) =>
                          dataType.value === newDataType.value,
                      )?.value || ''
                    : fileItem?.data_type
              }
              label="Other Data"
              name="other_data"
              variant="standard"
              onChange={(e: SelectChangeEvent) =>
                e.target.value.includes('other')
                  ? handleOtherDataChange(
                      e.target.value,
                      fileIndex,
                      'data_type',
                    )
                  : handleOtherDataChange(
                      `other_${e.target.value
                        ?.toLowerCase()
                        .replace(/ /g, '_')}`,
                      fileIndex,
                      'data_type',
                    )
              }
            >
              {otherDataTypesSelector?.map(
                (dataType: DataType, index: number) => (
                  <MenuItem key={index} value={dataType.value}>
                    {dataType.name}
                  </MenuItem>
                ),
              )}
              {newDataType?.value === undefined &&
                Object.keys(newDataType).length > 0 && (
                  <MenuItem
                    value={
                      otherDataTypesSelector
                        ? otherDataTypesSelector?.find(
                            (dataType: DataType) =>
                              dataType.value === newDataType.value,
                          )?.value
                        : newDataType?.value
                    }
                  >
                    {otherDataTypesSelector
                      ? otherDataTypesSelector?.find(
                          (dataType: DataType) =>
                            dataType.name === newDataType.name,
                        )?.name
                      : newDataType?.name}
                  </MenuItem>
                )}
              <Divider />
              <MenuItem
                value="create_data_type"
                onClick={() => {
                  setTempDataType({});
                  setCreateDataType(true);
                }}
              >
                Create a new data type
              </MenuItem>
            </Select>
          </FormControl>
        ) : (
          ''
        )}
        {lookUp || fileItem?.data_type?.includes('lookup') ? (
          <FormControl required fullWidth>
            <InputLabel id="demo-simple-select-label">
              Look Up Tables
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="lookup_tables"
              value={
                createLookUpTable || newLookUpTable?.value === ''
                  ? 'create_table'
                  : createLookUpTable && newLookUpTable?.value !== ''
                    ? lookupTablesSelector?.find(
                        (lookupTable: DataType) =>
                          lookupTable.value === newLookUpTable.value,
                      )?.value || ''
                    : fileItem?.data_type
              }
              label="Look Up Table"
              variant="standard"
              onChange={(e: SelectChangeEvent) =>
                e.target.value.includes('lookup')
                  ? handleLookUpTableChange(
                      e.target.value,
                      fileIndex,
                      'data_type',
                    )
                  : handleLookUpTableChange(
                      `lookup_${e.target.value
                        ?.toLowerCase()
                        .replace(/ /g, '_')}`,
                      fileIndex,
                      'data_type',
                    )
              }
            >
              {lookupTablesSelector?.map(
                (lookupTable: DataType, index: number) => (
                  <MenuItem key={index} value={lookupTable.value}>
                    {lookupTable.name}
                  </MenuItem>
                ),
              )}
              {newLookUpTable?.value === undefined &&
                Object.keys(newLookUpTable).length > 0 && (
                  <MenuItem
                    value={
                      lookupTablesSelector
                        ? lookupTablesSelector?.find(
                            (lookupTable: DataType) =>
                              lookupTable.value === newLookUpTable.value,
                          )?.value
                        : newLookUpTable?.value
                    }
                  >
                    {lookupTablesSelector
                      ? lookupTablesSelector?.find(
                          (lookupTable: DataType) =>
                            lookupTable.name === newLookUpTable.name,
                        )?.name
                      : newLookUpTable?.name}
                  </MenuItem>
                )}
              <Divider />
              <MenuItem
                value="create_table"
                onClick={() => {
                  setTempLookUpTable({});
                  setCreateLookUpTable(true);
                }}
              >
                Create a new look up table
              </MenuItem>
            </Select>
          </FormControl>
        ) : (
          ''
        )}
        <FormControl required fullWidth>
          <InputLabel id="demo-simple-select-label">Priority</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={fileItem.priority}
            label="Priority"
            variant="standard"
            onChange={(e: SelectChangeEvent) =>
              handleFileValueChange(e.target.value, fileIndex, 'priority')
            }
            required
          >
            <MenuItem value="mandatory">Mandatory</MenuItem>
            <MenuItem value="preferred">Preferred</MenuItem>
            <MenuItem value="skip">Skip</MenuItem>
          </Select>
        </FormControl>
        <div>
          <button
            type="button"
            className="remove-merchant-button"
            onClick={(event) => handleRemoveClick(event, fileIndex)}
          >
            <RemoveCircleSharpIcon />
          </button>
        </div>
      </div>
      <div className="trans-div">
        <div>
          {createDataType ? (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <TextField
                required
                type="text"
                label="New data type"
                variant="standard"
                value={tempDataType?.value || ''}
                onChange={(e) =>
                  setTempDataType({
                    name: e.target.value,
                    value: e.target.value,
                  })
                }
                inputProps={{ maxLength: 32 }}
                error={dataError ? true : false}
                helperText={dataError || ''}
                sx={{ marginBottom: '20px' }}
                onKeyDown={preventEnterSubmit}
              />
              <button
                type="button"
                className="remove-merchant-button"
                onClick={(e) => handleAddNewDataType(e, fileIndex, 'data_type')}
              >
                Save
              </button>
            </div>
          ) : (
            ''
          )}
          {createLookUpTable ? (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
              }}
            >
              <TextField
                required
                type="text"
                label="New look up table"
                variant="standard"
                value={tempLookUpTable?.value || ''}
                onChange={(e) =>
                  setTempLookUpTable({
                    name: e.target.value,
                    value: e.target.value,
                  })
                }
                inputProps={{ maxLength: 32 }}
                error={lookUpError ? true : false}
                helperText={lookUpError || ''}
                sx={{ marginBottom: '20px' }}
                onKeyDown={preventEnterSubmit}
              />
              <button
                type="button"
                className="remove-merchant-button"
                onClick={(e) =>
                  handleAddNewLookUpTable(e, fileIndex, 'data_type')
                }
              >
                Save
              </button>
            </div>
          ) : (
            ''
          )}
          <TagsInput
            type="text"
            required
            value={fileItem.col_name}
            onChange={(e) => handleFileValueChange(e, fileIndex, 'col_name')}
            name="col_name"
            placeHolder="enter column name"
          />
          <em>
            press enter to add one new column; or paste a comma separated list
            of several column names and press enter
          </em>
        </div>
        {fileItem.data_type === 'transaction_data' && (
          <>
            <div>
              <TagsInput
                type="text"
                required
                disabled={disableEditAcquirerName}
                value={fileItem.acquirer_name}
                onChange={(e) =>
                  handleFileValueChange(e, fileIndex, 'acquirer_name')
                }
                name="acquirer_name"
                placeHolder={
                  disableEditAcquirerName
                    ? 'acquirer name'
                    : 'enter acquirer name'
                }
              />
              <em>
                {disableEditAcquirerName
                  ? 'acquirer name is populated automatically and cannot be edited'
                  : 'press enter to add new acquirer'}
              </em>
            </div>
            <div>
              <TagsInput
                type="text"
                required
                disabled={disableEditMerchantName}
                value={fileItem.merchant_name}
                onChange={(e) =>
                  handleFileValueChange(e, fileIndex, 'merchant_name')
                }
                name="merchant_name"
                placeHolder={
                  disableEditMerchantName
                    ? `merchant name`
                    : `enter merchant name`
                }
              />
              <em>
                {disableEditMerchantName
                  ? 'merchant name is populated automatically and cannot be edited'
                  : 'press enter to add new merchant'}
              </em>
            </div>
          </>
        )}
        {sourceType.selected_type === 'MFT4U' && (
          <div>
            <TagsInput
              type="email"
              required
              value={fileItem.emails}
              onChange={(e) => handleFileValueChange(e, fileIndex, 'emails')}
              name="emails"
              placeHolder="enter email address"
            />
            <em>
              press enter to add new email (these email addresses will not be
              used for data requests)
            </em>
          </div>
        )}
        <TransformationsAccordion
          fileIndex={fileIndex}
          fileItem={fileItem}
          pathToSourceType={pathToSourceType}
          dataSourceIndex={dataSourceIndex}
          dataTypeIndex={dataTypeIndex}
          dataType={dataType}
        />

        <Divider color="black" />

        <ValidationsAccordion
          fileIndex={fileIndex}
          fileItem={fileItem}
          pathToSourceType={pathToSourceType}
          dataSourceIndex={dataSourceIndex}
          dataTypeIndex={dataTypeIndex}
          dataType={dataType}
        />
      </div>
    </div>
  );
};

export default AddFile;
