// @ts-nocheck
import TextField from '@mui/material/TextField';
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
import AddFile from '../../components/AddFile/AddFile';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { DATA_TYPE } from '../../constants/file';

import './AzureDataSource.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormField } from '../../slices/formFieldSlice';
import { toggleAccordion } from '../../slices/accordionSlice';
import { preventEnterSubmit } from '../../utils/inputs';

const AzureDataSource = ({ dataType, dataSourceIndex, dataTypeIndex }: any) => {
  const dispatch = useDispatch();
  const pathToSourceType = [
    dataType,
    dataSourceIndex,
    'data_source_type',
    dataTypeIndex,
  ];
  const sourceType = useSelector(
    (state) =>
      ['formField', ...pathToSourceType].reduce(
        (currentState, pathPart) => currentState[pathPart],
        state,
      ) || {},
  );
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );
  const isAccordionExpanded = expandedAccordions.includes(sourceType.uid);

  const handleValueChange = (val: any, fieldName: any) => {
    const editedSourceType = structuredClone(sourceType);
    editedSourceType[fieldName] = val;
    dispatch(
      updateFormField({ path: pathToSourceType, value: editedSourceType }),
    );
  };

  const addDataFile = (e: any) => {
    e.preventDefault();
    const editedSourceType = structuredClone(sourceType);
    editedSourceType.file = [
      ...editedSourceType.file,
      {
        file_name: '',
        type: '',
        priority: '',
        col_name: [],
        acquirer_name: [],
        merchant_name: [],
        data_type: '',
        transformation: [],
        validation: [],
        uid: crypto.randomUUID(),
      },
    ];
    editedSourceType.file_count = editedSourceType.file.length;
    dispatch(
      updateFormField({ path: pathToSourceType, value: editedSourceType }),
    );
  };

  return (
    <div className="azure-data-source-div">
      <div className="div-style" key={sourceType}>
        <TextField
          key={sourceType.selected_type + '-url'}
          required
          label="Blob URL"
          variant="standard"
          value={sourceType.url || ''}
          onChange={(e) => handleValueChange(e.target.value, 'url')}
          onKeyDown={preventEnterSubmit}
        />
        <TextField
          required
          key={sourceType.selected_type + 'sas_token'}
          label="Container SAS Token"
          variant="standard"
          value={sourceType.sas_token}
          onChange={(e) => handleValueChange(e.target.value, 'sas_token')}
          onKeyDown={preventEnterSubmit}
        />
      </div>
      <MuiAccordion
        id={sourceType.uid}
        expanded={isAccordionExpanded}
        onChange={() => dispatch(toggleAccordion(sourceType.uid))}
      >
        <MuiAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ArrowForwardIosSharpIcon />}
        >
          <Typography>Files</Typography>
        </MuiAccordionSummary>
        <MuiAccordionDetails>
          <div className="add-file-button-div">
            <p className="bold">Add Files</p>
            <button
              className="add-file-button"
              onClick={(e) => addDataFile(e)}
              type="button"
            >
              <AddCircleSharpIcon />
            </button>
          </div>
          {isAccordionExpanded &&
            sourceType?.file?.map((fileItem: any, fileIndex: any) => {
              const isSubAccordionExpanded = expandedAccordions.includes(
                fileItem.uid,
              );
              return (
                <MuiAccordion
                  id={fileItem.uid}
                  expanded={isSubAccordionExpanded}
                  onChange={() => dispatch(toggleAccordion(fileItem.uid))}
                  key={fileItem.uid}
                >
                  <MuiAccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ArrowForwardIosSharpIcon />}
                    sx={{
                      '& > .MuiAccordionSummary-content': {
                        width: '100%',
                      },
                    }}
                  >
                    <Typography
                      textOverflow="ellipsis"
                      sx={{
                        overflowX: 'clip',
                      }}
                    >{`${
                      DATA_TYPE[fileItem.data_type]
                    } | ${fileItem.merchant_name.join(
                      ', ',
                    )} | ${fileItem.acquirer_name.join(', ')} | ${
                      fileItem.file_name
                    }`}</Typography>
                  </MuiAccordionSummary>
                  <MuiAccordionDetails>
                    {isSubAccordionExpanded && (
                      <AddFile
                        dataType={dataType}
                        key={fileIndex}
                        fileItem={fileItem}
                        fileIndex={fileIndex}
                        pathToSourceType={pathToSourceType}
                        dataSourceIndex={dataSourceIndex}
                        dataTypeIndex={dataTypeIndex}
                      />
                    )}
                  </MuiAccordionDetails>
                </MuiAccordion>
              );
            })}
        </MuiAccordionDetails>
      </MuiAccordion>
    </div>
  );
};

export default AzureDataSource;
