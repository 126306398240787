// @ts-nocheck
import { useState, useRef, Fragment, ReactNode, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import PropagateLoader from 'react-spinners/PropagateLoader';
import Error from '../Error/Error';
import ClientDetails from '../../components/ClientDetails/ClientDetails';
import AddEmailTemplates from '../../components/AddEmailTemplates/AddEmailTemplates';
import CreateDataSource from '../../components/CreateDataSource/CreateDataSource';
import CreateEngagementSource from '../../components/CreateEngagementSource/CreateEngagementSource';
import CreateAcquirerSource from '../../components/CreateAcquirerSource/CreateAcquirerSource';
import AddMerchant from '../../components/AddMerchant/AddMerchant';
import ReviewDetails from '../../components/ReviewDetails/ReviewDetails';
import AddClientDataSource from '../../components/AddClientDataSource/AddClientDataSource';
import AddThirdPartyDataSource from '../../components/AddThirdPartyDataSource/AddThirdPartyDataSource';
import FinalTransformationsAndValidations from '../../components/FinalTransformationsAndValidations/FinalTransformationsAndValidations';
import { addBlob, getBlob } from '../../services/blobs';
import { enqueueSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTransformations } from '../../slices/transformationSlice';
import { resetState } from '../../slices/formFieldSlice';
import { fetchValidations } from '../../slices/validationSlice';
import { fetchBlobs } from '../../slices/blobSlice';
import { loadFiles } from '../../slices/fileSlice';
import { fetchAnalytics } from '../../slices/analyticsSlice';
import { setExpandedAccordions } from '../../slices/accordionSlice';
import AddReporting from '../../components/AddReporting/AddReporting';
import MenuBackNext from './MenuBackNext';
import { validateStep, prepareData, setCustomValidity } from './validation';
import './CreatePipeline.css';

const steps = [
  'Add client details',
  'Add email templates',
  'Add merchants',
  'Add merchant data sources',
  'Add client data sources',
  'Add engagement team data sources',
  'Add acquirer data sources',
  'Add third-party data sources',
  'Add final data transformations/validations',
  'Add reporting',
  'Review and submit',
];

const CreatePipeline = ({ LoadUrl, filePath }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isBlobLoading, setIsBlobLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [skipped, setSkipped] = useState(new Set<number>());
  const myForm = useRef<HTMLFormElement>(null);

  const dispatch = useDispatch();
  const activeStepIsLast = activeStep === steps.length - 1;
  const formField = useSelector((state) => state.formField);
  const engagementName = useSelector(
    (state) => state.formField.engagement_name,
  );
  const engagementDetailsStartData = useSelector(
    (state) => state.formField.engagement_details.engagement_start_date,
  );
  const engagementDetailsEndDate = useSelector(
    (state) => state.formField.engagement_details.engagement_end_date,
  );
  const engagementDetailsPeriodStart = useSelector(
    (state) => state.formField.engagement_details.first_reporting_period_start,
  );
  const engagementDetailsPeriodEnd = useSelector(
    (state) => state.formField.engagement_details.first_reporting_period_end,
  );
  const engagementDetailsEngagementTeam = useSelector(
    (state) => state.formField.engagement_details.engagement_team,
  );
  const engagementDetailsDataProviders = useSelector(
    (state) => state.formField.engagement_details.data_providers,
  );
  const expandedAccordions = useSelector(
    (state) => state.accordion.expandedAccordions,
  );
  const transformationsFieldsSelector = useSelector(
    (state: RootState) => state.transformations.transformationsFields,
  );
  const isTransformationLoading = useSelector(
    (state: RootState) => state.transformations.loading,
  );
  const transformationErrorMessage = useSelector(
    (state: RootState) => state.transformations.error,
  );

  const blobsSelector = useSelector((state: RootState) => state.blobs.blobs);
  const isBlobsLoading = useSelector((state: RootState) => state.blobs.loading);
  const blobsErrorMessage = useSelector(
    (state: RootState) => state.blobs.error,
  );

  const validationsFieldsSelector = useSelector(
    (state: RootState) => state.validations.validationsFields,
  );
  const isValidationLoading = useSelector(
    (state: RootState) => state.validations.loading,
  );
  const validationErrorMessage = useSelector(
    (state: RootState) => state.validations.error,
  );

  const analyticsSelector = useSelector(
    (state: RootState) => state.analytics.analytics,
  );
  const isAnalyticsLoading = useSelector(
    (state: RootState) => state.analytics.loading,
  );
  const analyticsErrorMessage = useSelector(
    (state: RootState) => state.analytics.error,
  );

  useEffect(() => {
    const clearValidationMessage = (event) => {
      if (
        myForm?.current &&
        event.target.form === myForm.current &&
        event.target.willValidate
      ) {
        event.target.setCustomValidity('');
        const fields = myForm.current.elements;
        for (let i = 0; i < fields.length; i++) {
          fields[i].setCustomValidity('');
        }
      }
    };

    myForm?.current?.addEventListener('click', clearValidationMessage);

    return () => {
      myForm?.current?.removeEventListener('click', clearValidationMessage);
    };
  }, []);

  useEffect(() => {
    if (!transformationsFieldsSelector.length) {
      dispatch(fetchTransformations());
    }

    if (!validationsFieldsSelector.length) {
      dispatch(fetchValidations());
    }

    if (!blobsSelector.length) {
      dispatch(fetchBlobs());
    }

    if (!analyticsSelector.length) {
      dispatch(fetchAnalytics());
    }
  }, []);

  useEffect(() => {
    if (isBlobsLoading === false && blobsErrorMessage) {
      enqueueSnackbar(blobsErrorMessage, {
        variant: 'error',
      });
    }
  }, [isBlobsLoading]);

  useEffect(() => {
    if (isTransformationLoading === false && transformationErrorMessage) {
      enqueueSnackbar(transformationErrorMessage, {
        variant: 'error',
      });
    }
  }, [isTransformationLoading]);

  useEffect(() => {
    if (isValidationLoading === false && validationErrorMessage) {
      enqueueSnackbar(validationErrorMessage, {
        variant: 'error',
      });
    }
  }, [isValidationLoading]);

  useEffect(() => {
    if (isAnalyticsLoading === false && analyticsErrorMessage) {
      enqueueSnackbar(analyticsErrorMessage, {
        variant: 'error',
      });
    }
  }, [isAnalyticsLoading]);

  let count2 = 0;

  useEffect(() => {
    const fetchData = async () => {
      setIsBlobLoading(true);

      try {
        const response = await getBlob(filePath);

        // destructure data sources from response
        const {
          acquirer_data_sources,
          client_data_sources,
          engagement_team_data_sources,
          merchant_data_sources,
          third_party_data_sources,
          final_data,
          analytics,
          final_transformation,
          final_validation,
        } = response;

        // todo remove this if all pipelines are updated to have their uid
        prepareData(acquirer_data_sources);
        prepareData(client_data_sources);
        prepareData(engagement_team_data_sources);
        prepareData(merchant_data_sources);
        prepareData(third_party_data_sources);
        prepareData(final_data);
        prepareData(analytics);
        prepareData(final_transformation, true);
        prepareData(final_validation, true);

        // dispatch files to the store
        dispatch(
          loadFiles(
            structuredClone([
              acquirer_data_sources,
              client_data_sources,
              engagement_team_data_sources,
              merchant_data_sources,
            ]),
          ),
        );

        let count = 0;
        // todo remove this if all pipelines are updated to have their uid
        function findMissingUids(obj, path = '') {
          if (Array.isArray(obj)) {
            obj.forEach((item, index) => {
              const currentPath = `${path}[${index}]`;
              if (Array.isArray(item) || typeof item === 'object') {
                findMissingUids(item, currentPath);
              }
            });
          } else if (typeof obj === 'object' && obj !== null) {
            if (!obj.hasOwnProperty('uid') && !obj.hasOwnProperty('id')) {
              console.log(`Missing uid in path: ${path}`);
              count++;
            }
            Object.entries(obj).forEach(([key, value]) => {
              const currentPath = path ? `${path}.${key}` : key;
              if (Array.isArray(value) || typeof value === 'object') {
                findMissingUids(value, currentPath);
              }
            });
          }
        }

        findMissingUids(response);

        dispatch(
          resetState({
            ...response,
            final_data: [],
          }),
        );
      } catch (error) {
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }

      setIsBlobLoading(false);
    };

    if (LoadUrl) {
      fetchData();
    }
  }, [LoadUrl]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const isStepOptional = (step: number) => {
    const skipStep = [];
    if (!engagementDetailsDataProviders.includes('merchant'))
      skipStep.push(1, 3);

    if (!engagementDetailsDataProviders.includes('engagement_team'))
      skipStep.push(4);
    return skipStep;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const checkValidity = (condition, field, msg) => {
    if (condition) {
      setCustomValidity(field, msg, myForm);
      return true;
    }
    setCustomValidity(field, '', myForm);
    return false;
  };

  //Optimised code to define the steps needed for each selection
  const RequiredSteps = () => {
    const stepIncrements = {
      'client': [0, 4, 8, 9, 10],
      'merchant': [0, 1, 2, 3, 8, 9, 10],
      'acquirer': [0, 1, 6, 8, 9, 10],
      'third_party': [0, 7, 8, 9, 10],
      'engagement_team': [0, 5, 8, 9, 10],
    };
    let uniqueValuesArray = [];
    engagementDetailsDataProviders.forEach(provider => {
      if (stepIncrements[provider]) {
        uniqueValuesArray = [...uniqueValuesArray, ...stepIncrements[provider]];
      }
    });
    return Array.from(new Set(uniqueValuesArray)).sort((a, b) => a - b);
  };
  const handleNext = async (e: any, activeStep: number) => {
    e.preventDefault();
    const [isJsonValid, uidPathToAdd] = validateStep({
      activeStep,
      formField,
      expandedAccordions,
      setExpandedAccordions,
      dispatch,
      myForm,
    });
    const checkMatch =
      blobsSelector?.some((pipeline) => pipeline.name === engagementName) ||
      false;

    if (
      checkValidity(
        engagementName.match(/[^a-zA-Z0-9_$]/g) &&
        engagementName.match(/[^a-zA-Z0-9_$]/g).length > 0,
        'engagement_name',
        'Engagement name can only include  alphanumeric characters and underscores exclusively.',
      ) ||
      checkValidity(
        !(engagementDetailsEngagementTeam.length > 0),
        'engagement_team',
        'Please fill in this field',
      ) ||
      checkValidity(
        isNaN(new Date(engagementDetailsStartData).getTime()) &&
        !!myForm.current['engagement_start_date'].value,
        'engagement_start_date',
        'Invalid date',
      ) ||
      checkValidity(
        isNaN(new Date(engagementDetailsEndDate).getTime()) &&
        !!myForm.current['engagement_end_date'].value,
        'engagement_end_date',
        'Invalid date',
      ) ||
      checkValidity(
        isNaN(new Date(engagementDetailsPeriodStart).getTime()) &&
        !!myForm.current['first_reporting_period_start'].value,
        'first_reporting_period_start',
        'Invalid date',
      ) ||
      checkValidity(
        isNaN(new Date(engagementDetailsPeriodEnd).getTime()) &&
        !!myForm.current['first_reporting_period_end'].value,
        'first_reporting_period_end',
        'Invalid date',
      ) ||
      checkValidity(
        !LoadUrl && checkMatch && activeStep === 0,
        'engagement_name',
        'Engagement name already exists, please create another',
      )
    ) {
      myForm?.current?.reportValidity();
      return;
    }

    let hasTagError = false;
    myForm.current
      .querySelectorAll('.rti--container input.rti--input')
      .forEach((element) => {
        if (element.value) {
          element.setCustomValidity('Press enter to add');
          hasTagError = true;
        } else {
          element.setCustomValidity('');
        }
      });
    if (!isJsonValid || !myForm.current.checkValidity() || hasTagError) {
      if (uidPathToAdd.length) {
        setTimeout(() => {
          myForm?.current?.reportValidity();
        }, uidPathToAdd.length > 4 ? 1000 : 600);
      } else {
        myForm?.current?.reportValidity();
      }
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    dispatch(setExpandedAccordions([]));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const uniqueValuesArray = RequiredSteps();
    // Find the current activeStep in the uniqueValuesArray and set the next value as the new activeStep
    const currentStepIndex = uniqueValuesArray.indexOf(activeStep);
    if (currentStepIndex !== -1 && currentStepIndex < uniqueValuesArray.length - 1) {
      const nextStep = uniqueValuesArray[currentStepIndex + 1];
      setActiveStep(nextStep);
    }
    console.log('activeStep', activeStep);
    if (activeStepIsLast) {
      setLoading(true);
      setError(false);
      setSuccess(false);

      try {
        await addBlob(formField);
        setSuccess(true);
        dispatch(fetchBlobs());
      } catch (error) {
        // setError(true);
        if (error?.request?.status === 400) {
          setActiveStep(() => steps.length - 1);
        }
        enqueueSnackbar(error.message, {
          variant: 'error',
        });
      }
      setLoading(false);
    }
  };

  const handleBack = () => {
    const uniqueValuesArray = RequiredSteps();
    const currentStepIndex = uniqueValuesArray.indexOf(activeStep);
    if (currentStepIndex > 0) {
      const previousStep = uniqueValuesArray[currentStepIndex - 1];
      setActiveStep(previousStep);
    }
  };

  const _renderStepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <ClientDetails myForm={myForm} disabled={LoadUrl ? true : false} />
        );
      case 1:
        return <AddEmailTemplates />;
      case 2:
        return <AddMerchant />;
      case 3:
        return <CreateDataSource />;
      case 4:
        return <AddClientDataSource />;
      case 5:
        return <CreateEngagementSource />;
      case 6:
        return <CreateAcquirerSource />;
      case 7:
        return <AddThirdPartyDataSource />;
      case 8:
        return <FinalTransformationsAndValidations />;
      case 9:
        return <AddReporting />;
      case 10:
        return <ReviewDetails setActiveStep={setActiveStep} />;
      default:
        return '';
    }
  };

  if (error) return <Error />;
  if (
    loading ||
    isBlobLoading ||
    isBlobsLoading ||
    isTransformationLoading ||
    isValidationLoading ||
    isAnalyticsLoading
  )
    return (
      <div className="loading">
        <PropagateLoader color="black" />
      </div>
    );

  return (
    <>
      <div className="create-pipeline-container">
        <h4 className="create-pipeline-h1">
          {LoadUrl
            ? `Edit ${engagementName} pipeline`
            : 'Create a new Customer Insights pipeline'}
        </h4>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Stepper
            activeStep={activeStep}
            sx={{ width: '131%', margin: '20px 0' }}
          >
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: ReactNode;
              } = {};
              if (isStepOptional(index)) {
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {activeStep === 9 && (
            <Container maxWidth="xl">
              <Alert
                key="warning-step-10"
                severity="info"
                sx={{ justifyContent: 'center' }}
              >
                This interface is currently in its beta phase, designed for the
                purpose of gathering user feedback. Please be aware that certain
                functionalities might not be fully operational at this time.
              </Alert>
            </Container>
          )}
          <form ref={myForm} className="create-pipeline-form">
            {success ? (
              <Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  All steps for creating a data pipeline submitted. The data
                  pipeline should be created within several minutes.
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button href="/">Back to Home Page</Button>
                </Box>
              </Fragment>
            ) : (
              <Fragment>
                <MenuBackNext
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  steps={steps}
                />
                {_renderStepContent(activeStep)}
                <MenuBackNext
                  activeStep={activeStep}
                  handleBack={handleBack}
                  handleNext={handleNext}
                  steps={steps}
                />
              </Fragment>
            )}
          </form>
        </Box>
      </div>
    </>
  );
};

export default CreatePipeline;
